.content {
  display: flex;
  flex-flow: column nowrap;

  width: 100%;
  max-width: $width-content;
  margin: auto;

  h2 {
    display: block;

    margin: 1.25rem 0;

    text-align: center;
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 1.375;

    color: $color-white;
  }
}