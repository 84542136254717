.form {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  width: 100%;
  min-height: 18.75rem;
  margin: 0 0 2rem;
  padding: 1.5rem;

  color: $color-text;
  background-color: $color-white;

  box-shadow: 0.375rem 0.375rem 0 0.0625rem rgba($color-shadow, 0.66);
  border-radius: 0.75rem;

  &.form--initialize {
    align-items: center;
    justify-content: center;
  }

  &.form--connect {
    align-items: center;
    justify-content: center;
  }

  &.form--success {
    align-items: center;
    justify-content: center;
  }

  &.form--welcome {
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 479px) {
    min-height: 10rem;
  }
}