@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: local('Manrope'),
       url('./fonts/nunito-regular.woff2') format('woff2'),
       url('./fonts/nunito-regular.woff') format('woff');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: local('Manrope'),
       url('./fonts/nunito-bold.woff2') format('woff2'),
       url('./fonts/nunito-bold.woff') format('woff');
}